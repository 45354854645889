import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/layout"
import Seo from "../components/global/Seo/seo"
import CTALink from "../components/global/CTALink/CTALink"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import CmsContent from "./../components/CmsContent/CmsContent"

export const aboutPage = graphql`
  query aboutPage {
    prismicCvswapoutAbout {
      data {
        name
        seo {
          document {
            ...seoData
          }
        }
        hero_banner {
          document {
            ...heroBannerData
          }
        }
        body {
          ... on PrismicCvswapoutAboutDataBodyRichtext {
            id
            slice_type
            primary {
              content {
                richText
                html
              }
            }
          }
        }
        contact_cta {
          document {
            ... on PrismicCvswapoutCta {
              id
              data {
                href
                label
                name
                open_in_new_tab
              }
            }
          }
        }
        download_cta {
          document {
            ... on PrismicCvswapoutCta {
              id
              data {
                href
                label
                name
                open_in_new_tab
              }
            }
          }
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <Seo data={data.prismicCvswapoutAbout.data.seo.document.data} />
      <HeroBanner
        data={data.prismicCvswapoutAbout.data.hero_banner.document.data}
      />
      <article className="about static-page-width">
        <section className="container article-body">
          <div className={`col-sm-12 col-md-8 offset-md-2`}>
            <CmsContent article={data.prismicCvswapoutAbout.data.body} />

            <CTALink
              to={
                data.prismicCvswapoutAbout.data.download_cta.document.data.href
              }
              target={`_blank`}
              className="btn btn-primary mb-3 mb-md-0 me-md-4"
              rel={`noreferrer`}
            >
              {data.prismicCvswapoutAbout.data.download_cta.document.data.label}
            </CTALink>

            <CTALink
              to={
                data.prismicCvswapoutAbout.data.contact_cta.document.data.href
              }
              target={`_blank`}
              className="btn btn-primary white"
              rel={`noreferrer`}
            >
              {data.prismicCvswapoutAbout.data.contact_cta.document.data.label}
            </CTALink>
          </div>
        </section>
      </article>
    </Layout>
  )
}
export default AboutPage
